<template>
  <main class="pdf_content">
    <section class="html2pdf__page-break pdf-item pdf-header">
      <img
        src="./../../assets/pdfLayout/pdf-title-odontologos.png"
        class="img-title"
        alt="Cartilla de Prestadores"
      />
      <ul class="planProvince">
        <li>
          Provincia: <strong>{{ initialInfo.province }}</strong>
        </li>
      </ul>
      <p
        class="planCities"
        :key="key_cities"
        v-if="this.initialInfo.town !== null"
      >
        Localidad / Zona:
        <template v-for="(i, k) in initialInfo.town">
          <strong :key="k" v-if="k > 0">, {{ i.name }}</strong>
          <strong :key="k" v-else>{{ i.name }}</strong>
        </template>
      </p>
      <img
        class="img-circle"
        src="./../../assets/pdfLayout/header-item-4.png"
      />
      <img
        class="img-quote"
        src="./../../assets/pdfLayout/header-item-5.png"
        alt="nuestro plan es cuidarte"
      />
    </section>

    <template v-if="this.groupData.length > 0">
      <section
        v-for="(group, id) in this.groupData"
        :key="id"
        class="html2pdf__page-break pdf-item"
      >
        <div class="container is-fluid pt-3">
          <div class="columns is-multiline is-mobile">
            <PLIOdontologos
              v-for="(i, k) in group"
              :key="k"
              :name="i.ApellidoNombre"
              :dir="i.Direccion"
              :num="i.Numero"
              :dep="i.Depto"
              :localidad="i.Localidad"
              :provincia="i.Provincia"
              :tel="i.Telefono"
              :esp="i.Especialidad"
              :plan="i.PlanesHabilitados"
            />
          </div>
        </div>
        <!-- <div class="group-footer">
          <img
            class="group-circle"
            src="./../../assets/pdfLayout/body-item-1.png"
          />
          <img
            class="group-footer"
            src="./../../assets/pdfLayout/body-item-2.png"
          />
        </div> -->
      </section>
    </template>

    <section class="pdf-footer">
      <img
        class="pdf-item html2pdf__page-break img-footer"
        src="./../../assets/pdfLayout/footer-item-1.png"
      />
    </section>
    <!--  -->
  </main>
</template>

<script>
import PLIOdontologos from "./PLIOdontologos";
export default {
  name: "PdfLayout",
  props: ["list"],
  components: {
    PLIOdontologos,
  },
  data() {
    return {
      initialInfo: {},
      maxItemGroup: 13, // tomara uno menos
      allData: this.list,
      partData: [],

      groupData: [],
      key_cities: 0,
    };
  },
  watch: {
    initialInfo: function () {
      this.key_cities++;
    },
  },
  created() {
    // console.log(
    //   "Count all data: " + this.list.length,
    //   "Count max group: " + this.maxItemGroup
    // );
    this.initialInfo = this.$store.getters.getInfoPdf;
    this.createDataForPagination();
  },
  methods: {
    // paginacion
    setPage(p) {
      this.partData = this.allData.slice(
        (p - 1) * this.maxItemGroup,
        p * this.maxItemGroup - 1
      );
    },

    createDataForPagination() {
      const totalPages = Math.ceil(this.allData.length / this.maxItemGroup);
      let i = 1;
      this.setPage(i++);

      this.groupData = [];

      while (i <= totalPages + 1) {
        // + 1 por la pagina del footer
        this.groupData.push(this.partData);
        this.setPage(i++);
      }
    },
  },
};
</script>

<style scoped>
.pdf_content {
  background-color: #f3f3f3ff;
}
.on-top {
  margin-top: -120px;
}

.pdf-item {
  height: 790px;
}

.pdf-item,
.pdf-header,
.pdf-footer {
  position: relative;
}

.pdf-header .img-title {
  position: absolute;
  left: 5%;
  top: 25%;
}
.planCities {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 5%;
  top: 42%;
  font-size: 24px;
  color: #00758f;
}
.planCities strong {
  color: #00758f;
}
.planProvince {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 5%;
  top: 35%;
}
.planProvince li {
  display: inline-block;
  margin-right: 20px !important;
  font-size: 24px;
  color: #00758f;
  margin-top: 0 !important;
}
.planProvince li strong {
  color: #00758f;
}
.planProvince li img {
  vertical-align: text-bottom;
}
.planProvince li:last-child {
  margin-right: 0px;
}

.pdf-header .imt-plan {
  position: absolute;
  left: 0;
  top: 20%;
}

.pdf-header .imt-title {
  position: absolute;
  left: 0;
  top: 10%;
}

.pdf-header .img-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.pdf-header .img-quote {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.pdf-footer .img-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 742px;
}
.group-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.group-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>