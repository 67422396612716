<template>
  <form @submit="sendForm" id="contentFix">
    <div class="columns is-multiline is-mobile is-justify-content-center">
      <div class="column is-12-touch is-auto-desktop has-text-left">
        <label class="typo__label">Plan</label>
        <multiselect
          v-model="planSelected"
          track-by="name"
          label="name"
          placeholder="Seleccionar"
          :options="planData"
          :searchable="false"
          :showLabels="false"
          :openDirection="'bottom'"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <strong>{{ option.name }}</strong>
          </template>
        </multiselect>
      </div>
      <div class="column is-12-touch is-auto-desktop has-text-left">
        <label class="typo__label">Provincia</label>
        <multiselect
          v-model="provinceSelected"
          track-by="name"
          label="name"
          placeholder="Seleccionar"
          :options="provinceData"
          :searchable="false"
          :showLabels="false"
          :openDirection="'bottom'"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{
            option.name
          }}</template>
        </multiselect>
      </div>
      <div class="column is-12-touch is-auto-desktop has-text-left">
        <label class="typo__label">Tipo</label>
        <multiselect
          v-model="typologiSelected"
          track-by="name"
          label="name"
          placeholder="Seleccionar"
          :options="typologiData"
          :searchable="false"
          :showLabels="false"
          :openDirection="'bottom'"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{
            option.name
          }}</template>
        </multiselect>
      </div>
      <div class="column is-12-touch is-auto-desktop has-text-left">
        <label class="typo__label">Especialidad</label>
        <Multiselect
          v-model="skillSelected"
          placeholder="Seleccionar"
          label="Descripcion"
          :limit="1"
          track-by="Descripcion"
          :options="skillData"
          :multiple="true"
          :taggable="true"
          :openDirection="'bottom'"
          :showLabels="false"
          :limitText="(count) => `y ${count} más`"
          @tag="addTag"
        >
          <template slot="option" slot-scope="props">{{
            props.option.Descripcion
          }}</template>
        </Multiselect>
      </div>
      <div class="column is-12-touch is-auto-desktop has-text-centered">
        <button type="submit" class="button is-info is-fullwidth">
          Buscar
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import Multiselect from "vue-multiselect";
import Planes from "./../../assets/data/plan.json";
import Provincias from "./../../assets/data/provincias.json";
import Especialidades from "./../../assets/data/especialidades.json";
export default {
  name: "FormPrestadores",
  data() {
    return {
      planSelected: [],
      provinceSelected: [],
      skillSelected: [],
      typologiSelected: [],
      planData: [],
      provinceData: [],
      typologiData: [
        { name: "TODAS", value: "Todas" },
        { name: "PROFESIONAL-CONSULTORIO", value: "Profesional" },
        { name: "CENTROS", value: "Centro" },
        { name: "CLÍNICAS-SANATORIOS", value: "Clinica" },
        { name: "OTRA", value: "Otro" },
      ],
      skillData: [],
    };
  },
  components: {
    Multiselect,
  },
  created() {
    this.planData = Planes.plan;
    this.provinceData = Provincias.provincias;
    this.skillData = Especialidades;
  },
  methods: {
    sendForm(e) {
      e.preventDefault();
      if (this.planSelected == null) {
        this.$buefy.snackbar.open(`Seleccione un plan`);
        return;
      }

      if (this.provinceSelected == null) {
        this.$buefy.snackbar.open(`Seleccione una provincia`);
        return;
      }

      this.$emit("change", {
        plan: this.planSelected.id,
        province: this.provinceSelected.id,
        typologi: this.typologiSelected,
        skills: this.skillSelected,
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>
<style scoped>
button {
  margin-top: 39px;
  background-color: #00758f !important;
  font-weight: bold;
}
</style>