<template>
  <article class="column is-4-desktop pb-0">
    <div class="detail has-text-left	">
      <span class="alias">{{ this.alias }}</span>
      <span class="skill">
        <template v-if="this.tipology !== ''"> {{ " | " + this.tipology }} </template>
      </span>
      <span class="info">
        DIRECCIÓN: {{ this.address !== "" ? this.address + ", " : "" }}
        {{ this.city !== "" ? this.city + ", " : "" }}
        {{ this.province !== "" ? this.province : "" }}
        <br />
        <template v-if="this.telephone !== ''">
          TELÉFONO: {{ this.telephone }} <br />
        </template>
        <template v-if="this.skills.length > 0">
          <div class="info-skills">
            <span>ESPECIALIDAD:</span>
            {{ skills }}
          </div>
        </template>
      </span>
    </div>
  </article>
</template>

<script>
export default {
  name: "PdfLayoutItem",
  props: ["alias", "skill", "address", "city", "province", "telephone", "tipology"],
  data(){
    return{
      skills: Object.values(this.skill).toString().trim().replaceAll(",", ", ")
      // skills: this.skill
    }
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}
ul li {
  text-align: left;
}
.detail .alias {
  color: #00758f;
  font-size: 18px;
}
.detail .skill {
  color: #424242;
  font-size: 12px;
  font-style: italic;
}
.detail .info {
  display: block;
  color: #424242;
  font-size: 10px;
  font-weight: 300;
}
.info-skills{
  display: block;
  font-size: 7px;
  font-style: italic;
  font-weight: 300;
}
.info-skills span{
  font-size: 8px;
  font-style: normal;
}
</style>
