<template>
  <section class="red-socials">
    <template v-if="type == 'header'">
      <section v-if="responsive" class="menu-style">
        <img src="./../../assets/header/title-background.svg" />
        <ul class="two-grid left-grid mt-5">
          <li><h2 class="title">SEGUINOS</h2></li>
          <li class="second">
            <img src="./../../assets/social/en-las-redes.svg" />
          </li>
        </ul>
        <ul class="two-grid center-grid" style="grid-gap: 10px">
          <li v-for="(i, k) in social" :key="k">
            <a :href="i.url" target="_blank">
              <img class="imgHeader" :src="i.image" :alt="i.title" />
            </a>
          </li>
        </ul>
      </section>
      <section v-else class="menu-style">
        <img
          src="./../../assets/header/title-background.svg"
          class="titleBackground"
        />
        <ul class="two-grid left-grid mt-5">
          <li><h2 class="title">SEGUINOS</h2></li>
          <li class="second">
            <img src="./../../assets/social/en-las-redes.svg" />
          </li>
        </ul>
        <ul class="icons">
          <li v-for="(i, k) in social" :key="k">
            <a :href="i.url" target="_blank">
              <img class="imgHeader" :src="i.image" :alt="i.title" />
            </a>
          </li>
        </ul>
      </section>
    </template>
    <template v-else>
      <section v-if="responsive">
        <ul class="two-grid center-grid mt-5" style="grid-gap: 10px">
          <li v-for="(i, k) in social" :key="k">
            <a :href="i.url" target="_blank">
              <img
                class="icon"
                style="width: 32px; height: 32px"
                :src="i.image"
                :alt="i.title"
              />
            </a>
          </li>
        </ul>
        <ul class="two-grid center-grid" style="max-width: 90%; margin: 0 auto">
          <li><h2 class="title">SEGUINOS</h2></li>
          <li class="second">
            <img src="./../../assets/social/en-las-redes.svg" alt="" />
          </li>
        </ul>
      </section>
      <section v-else>
        <ul class="four-grid">
          <li><h2 class="title">SEGUINOS</h2></li>
          <li>
            <img src="./../../assets/social/en-las-redes.svg" alt="" />
          </li>
          <li v-for="(i, k) in social" :key="k">
            <a :href="i.url" target="_blank">
              <img
                style="width: 32px; height: 32px"
                :src="i.image"
                :alt="i.title"
              />
            </a>
          </li>
        </ul>
      </section>
    </template>
  </section>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import imgFacebook from './../../assets/social/facebook.svg'
import imgInstagram from './../../assets/social/instagram.svg'
export default {
  name: 'RedSocials',
  props: ['type'],
  data() {
    return {
      responsive: false,
      social: [
        {
          url: 'https://www.facebook.com/osfatunoficial',
          title: 'Facebook',
          image: imgFacebook,
        },
        {
          url: 'https://www.instagram.com/osfatunoficial',
          title: 'Instagram',
          image: imgInstagram,
        },
      ],
    }
  },
  mounted() {
    this.responsive = isMobile
  },
}
</script>

<style scoped>
.four-grid {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  align-items: center;
}
.four-grid li {
  font-weight: lighter;
  text-align: center;
  vertical-align: text-bottom;
  margin-bottom: 0;
}
.four-grid li.second {
  padding-bottom: 15px;
}
.icons {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: center;
  width: 100%;
}
.icons li {
  color: #000;
  font-weight: lighter;
  display: grid;
  float: left;
  font-size: 20px;
  align-content: center;
  justify-content: center;
  line-height: 1rem;
  position: relative;
  text-align: center;
  border: 0;
  vertical-align: text-bottom;
}
.icons li a {
  color: #fff;
  font-size: 16px;
  line-height: 1.3rem;
}
ul.icons li h2.title {
  margin: 0;
  padding: 0;
}
ul.icons li.second {
  padding-bottom: 15px;
}
.title {
  color: #00758f;
}
.imgHeader {
  width: 38px;
  height: 38px;
  filter: brightness(0) invert(1);
}
.menu-style {
  position: absolute;
  top: 10%;
  right: 12%;
}
.menu-style .icons {
  text-align: left;
  justify-content: left;
}
.menu-style .titleBackground {
  width: 100%;
  max-width: 100%;
  height: 300px;
}
.menu-style .title {
  color: #fff;
}
.two-grid {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: auto auto;
  align-items: center;
}
.two-grid .second {
  padding-bottom: 15px;
}
.left-grid {
  justify-content: start;
}
.center-grid {
  justify-content: center !important;
}
@media screen and (max-width: 42em) {
  .menu-style {
    position: relative;
    top: initial;
    right: initial;
  }
}
</style>
