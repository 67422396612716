import Vue from "vue";
import Vuex from "vuex";
import VuexPersistenceState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    paramsTable: {
      stateId: "",
      planImportCode: "",
    },
    totalList: {
      total: 0,
      page: 1,
    },
    infoPdf: {}
  },
  getters: {
    getParamsTable: (state) => {
      return state.paramsTable;
    },
    getTotalList: (state) => {
      return state.totalList;
    },
    getInfoPdf: (state) => {
      return state.infoPdf;
    }
  },
  mutations: {
    setParamsTable: (state, payload) => {
      state.paramsTable = payload;
    },
    setTotalList: (state, payload) => {
      state.totalList = payload;
    },
    setInfoPdf: (state, payload) => {
      state.infoPdf = payload;
    }
  },
  actions: {},
  plugins: [
    VuexPersistenceState({
      reducer: (persistedState) => {
        const stateFilter = Object.assign({}, persistedState);
        const blackList = ["totalList"];

        blackList.forEach((item) => {
          delete stateFilter[item];
        });

        return stateFilter;
      },
    }),
  ],
});
