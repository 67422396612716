<template>
  <article class="container-fluid">
    <div class="columns">
      <div class="column is-1">
        <img
          src="./../../assets/avatar.svg"
          alt="avatar"
          width="38px"
          height="44px"
        />
      </div>
      <div class="column">
        <div class="detail has-text-left">
          <span class="name">{{ this.name }}</span>
          <span class="skill">
            <template v-if="this.esp !== ''"> {{ "| " + this.esp }} <br /> </template>
          </span>
          <span class="info">
            <template v-if="this.plan !== ' '">PLANES: {{ this.plan }} <br /> </template>
            DIRECCIÓN: {{ this.dir !== "" ? this.dir: "" }}
            <template v-if="this.num !== ''">&nbsp;{{this.num}}</template>
            <template v-if="this.dep !== ''">&nbsp;Depto: {{this.dep}}</template>
            , {{this.localidad}}, {{this.provincia}}
            <br />
            <template v-if="this.tel !== ''">
              TELÉFONO: {{ this.tel }}
            </template>
          </span>
        </div>
      </div>
      <div class="column is-2">
        <template v-if="this.tel !== ''">
          <a :href="'tel:' + this.parseTel" class="button">LLAMAR</a>
        </template>
      </div>
    </div>
    <hr />
  </article>
</template>

<script>
export default {
  name: "Professional",
  props: ["name", "dir", "localidad", "provincia", "num", "dep", "tel", "esp", "plan"],
  data(){
    return{
      parseTel: '',
    }
  },
  created(){
    this.parseTel = this.tel.toString().replace('(','');
    this.parseTel = this.parseTel.toString().replace(')','');
  }
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}
ul li {
  text-align: left;
}
.detail .name {
  color: #00758f;
  font-size: 21px;
}
.detail .skill {
  color: #424242;
  font-size: 16px;
  font-style: italic;
}
.detail .info {
  display: block;
  color: #424242;
  font-size: 16px;
  text-transform: uppercase;
}
.button {
  display: none;
}
@media screen and (max-width: 42em) {
  .button {
    display: block;
  }
}
</style>
