<template>
  <article class="container-fluid">
    <div class="columns is-multiline is-mobile">
      <div class="column is-2-mobile is-1-desktop">
        <img
          src="./../../assets/plus-circle-solid.svg"
          alt="avatar"
          width="38px"
          height="44px"
        />
      </div>
      <div class="column">
        <span class="name">{{ name }}</span>
        <span class="info">
          <template v-if="dir !== ''"> DIRECCIÓN: {{ dir }}, {{ zona }}, {{ region }} </template>
          <br v-if="dir !== '' && tel !== ''" />
          <template v-if="tel !== ''"> TELÉFONO: {{ tel }} </template>
        </span>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Farmacia",
  props: ["name", "dir", "region", "zona", "tel", "code"],
};
</script>

<style scoped>
.name {
  display: block;
  color: #00758f;
  font-size: 21px;
  text-transform: lowercase;
}
.name::first-letter{
  text-transform: uppercase;
}
.info {
  color: #424242;
  font-size: 16px;
  text-transform: uppercase;
}
</style>