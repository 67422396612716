
import { plan } from "./../assets/data/plan.json";
import { provincias } from "./../assets/data/provincias.json";

export const getPlanName = (id)=>{
  let result = ""
  plan.forEach(e => {
    if (e.id === id) {
      result = e.name
    }
  })
  return result;
}

export const getProvinciaName = (id)=>{
  let result = ""
  provincias.forEach(e=>{
    if (e.id === id) {
      result = e.name
    }
  })
  return result
}