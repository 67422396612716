<template>
  <article class="column is-4-desktop pb-0">
    <div class="detail has-text-left">
      <span class="name">{{ name }}</span>
      <span class="info">
        <template v-if="dir !== ''"> DIRECCIÓN: {{ dir }}, {{ zona }}, {{ region }} </template>
        <br v-if="dir !== '' && tel !== ''" />
        <template v-if="tel !== ''"> TELÉFONO: {{ tel }} </template>
      </span>
    </div>
  </article>
</template>

<script>
export default {
  name: "PdfLayoutItem",
  props: ["name", "dir", "region", "zona", "tel"],
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}
ul li {
  text-align: left;
}
.detail{
  padding-bottom: 10px;
}
.detail .name {
  color: #00758f;
  font-size: 20px;
}
.detail .skill {
  color: #424242;
  font-size: 16px;
  font-style: italic;
}
.detail .info {
  display: block;
  color: #424242;
  font-size: 16px;
  text-transform: uppercase;
}
</style>
