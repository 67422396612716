<template>
  <article class="container-fluid">
    <div class="columns">
      <div class="column is-1">
        <img
          src="./../../assets/avatar.svg"
          alt="avatar"
          width="38px"
          height="44px"
        />
      </div>
      <div class="column">
        <div class="detail has-text-left	">
          <span class="alias">{{ this.alias }}</span>
          
          <span class="skill">
            <template v-if="this.tipology !== ''"> {{ "| " + this.tipology }} </template>
          </span>
          <span class="info">
            DIRECCIÓN: {{ this.address !== "" ? this.address + ", " : "" }}
            {{ this.city !== "" ? this.city + ", " : "" }}
            {{ this.province !== "" ? this.province : "" }}
            <br />
            <template v-if="this.telephone !== ''">
              TELÉFONO: {{ this.telephone }}
            </template>
            <br />
            <template v-if="skills !== ''">
              <span class="info-skills"> ESPECIALIDAD: {{ skills }}</span>
            </template>
          </span>
        </div>
      </div>
      <div class="column is-2">
        <template v-if="this.telephone !== ''">
          <a :href="'tel:' + this.telephone" class="button call">LLAMAR</a> <br />
        </template>
        <template v-if="this.homepage !== ''">
          <a :href=" this.homepage " class="button site" target="_blank">SITIO WEB</a>
        </template>
      </div>
    </div>
    <hr />
  </article>
</template>

<script>
export default {
  name: "Professional",
  props: ["homepage","alias", "skill", "address", "city", "province", "telephone", "tipology"],
  data(){
    return{
      skills: Object.values(this.skill).toString().trim().replaceAll(",", ", ")
    }
  }
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}
ul li {
  text-align: left;
}
.detail .alias {
  color: #00758f;
  font-size: 21px;
}
.detail .skill {
  color: #424242;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}
.detail .info {
  display: block;
  color: #424242;
  font-size: 16px;
  font-weight: 300;
}
.info-skills{
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}
.call{ display: none; }
.site { display: block; }
@media screen and (max-width: 42em) {
  .call {
    display: block;
  }
}
</style>
