import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);

import VueMeta from "vue-meta";
Vue.use(VueMeta);


import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
