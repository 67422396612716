<template>
  <main class="pdf_content">
    <section class="html2pdf__page-break pdf-item pdf-header">
      <img
        src="./../../assets/pdfLayout/pdf-title-prestadores.png"
        class="img-title"
        alt="Cartilla de Prestadores"
      />
      <ul class="planProvince">
        <li>
          Plan: <strong>{{ initialInfo.plan }}</strong>
        </li>
        <li>
          Red prestacional: <strong>{{ initialInfo.province }}</strong>
        </li>
      </ul>
      <p
        class="planCities"
        :key="key_cities"
        v-if="this.initialInfo.cities !== null"
      >
        Dominio:
        <template v-for="(i, k) in initialInfo.cities">
          <strong :key="k" v-if="k > 0">, {{ i.name }}</strong>
          <strong :key="k" v-else>{{ i.name }}</strong>
        </template>
      </p>
      <img
        class="img-circle"
        src="./../../assets/pdfLayout/header-item-4.png"
        alt=""
      />
      <img
        class="img-quote"
        src="./../../assets/pdfLayout/header-item-5.png"
        alt="nuestro plan es cuidarte"
      />
    </section>
    <template v-if="this.groupData.length > 0">
      <section
        v-for="(group, id) in this.groupData"
        :key="id"
        class="html2pdf__page-break pdf-item"
      >
        <div class="container is-fluid pt-3">
          <div class="columns is-multiline is-mobile">
            <PLIPrestadores
              v-for="(i, k) in group"
              :key="k"
              :homepage="i.Homepage"
              :email="i.Email"
              :alias="i.Alias"
              :skill="i.Especialidad"
              :address="i.Direccion"
              :tipology="i.Tipologia"
              :city="i.Ciudad"
              :province="i.Provincia"
              :telephone="i.Telefono"
            />
          </div>
        </div>
        <div class="group-footer">
          <img
            src="./../../assets/pdfLayout/body-item-2-1.png"
            class="img-1"
            alt=""
          />
          <img
            src="./../../assets/pdfLayout/body-item-2-2.png"
            class="img-2"
            alt=""
          />
        </div>
      </section>
    </template>
    <section class="pdf-footer">
      <img
        class="pdf-item html2pdf__page-break img-footer"
        src="./../../assets/pdfLayout/footer-item-1.png"
        alt=""
      />
    </section>
    <!--  -->
  </main>
</template>

<script>
import PLIPrestadores from "./PLIPrestadores";
export default {
  name: "PdfLayout",
  props: ["list"],
  components: {
    PLIPrestadores,
  },
  data() {
    return {
      initialInfo: {
        plan: "",
        province: "",
        cities: null,
      },
      maxItemGroup: 15, // tomara uno menos
      allData: this.list,
      partData: [],

      groupData: [],
      key_cities: 0,
    };
  },
  watch: {
    initialInfo: function () {
      this.key_cities++;
    },
  },
  created() {
    this.initialInfo = this.$store.getters.getInfoPdf;

    this.createDataForPagination();
  },
  methods: {
    // paginacion
    setPage(p) {
      this.partData = this.allData.slice(
        (p - 1) * this.maxItemGroup,
        p * this.maxItemGroup
      );
    },

    createDataForPagination() {
      const totalPages = Math.ceil(this.allData.length / this.maxItemGroup);
      let i = 1;
      this.setPage(i++);

      this.groupData = [];

      while (i <= totalPages + 1) {
        // + 1 por la pagina del footer
        this.groupData.push(this.partData);
        this.setPage(i++);
      }
    },
  },
};
</script>

<style scoped>
.pdf_content {
  background-color: #f3f3f3ff;
}
.on-top {
  margin-top: -120px;
}

.pdf-item {
  height: 790px;
}

.pdf-item,
.pdf-header,
.pdf-footer {
  position: relative;
}

.pdf-header .img-title {
  position: absolute;
  left: 5%;
  top: 25%;
}
.planCities {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 5%;
  top: 42%;
  font-size: 24px;
  color: #00758f;
}
.planCities strong {
  color: #00758f;
}
.planProvince {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 5%;
  top: 35%;
}
.planProvince li {
  display: inline-block;
  margin-right: 20px !important;
  font-size: 24px;
  color: #00758f;
  margin-top: 0 !important;
}
.planProvince li strong {
  color: #00758f;
}
.planProvince li img {
  vertical-align: text-bottom;
}
.planProvince li:last-child {
  margin-right: 0px;
}

.pdf-header .imt-plan {
  position: absolute;
  left: 0;
  top: 20%;
}

.pdf-header .imt-title {
  position: absolute;
  left: 0;
  top: 10%;
}

.pdf-header .img-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.pdf-header .img-quote {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.pdf-footer .img-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 742px;
}
.group-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.group-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100px;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: center;

  max-width: 96%;
  margin: 0 auto;
}
.group-footer .img-1 {
  height: 90px;
}
.group-footer .img-2 {
  height: 70px;
}
</style>