<template>
  <article class="column is-4-desktop pb-0">
    <div class="detail has-text-left">
      <span class="name">{{ this.name }}</span>
      <span class="skill">
        <template v-if="this.esp !== ''"> {{ "| " + this.esp }} <br /> </template>
      </span>
      <span class="info">
        <template v-if="this.plan !== ' '">PLANES: {{ this.plan }} <br /> </template>
        DIRECCIÓN: {{ this.dir !== "" ? this.dir: "" }}
        <template v-if="this.num !== ''">&nbsp;{{this.num}}</template>
        <template v-if="this.dep !== ''">&nbsp;Depto: {{this.dep}}</template>
        , {{this.localidad}}, {{this.provincia}}
        <br />
        <template v-if="this.tel !== ''">
          TELÉFONO: {{ this.tel }}
        </template>
      </span>
    </div>
  </article>
</template>

<script>
export default {
  name: "PdfLayoutItem",
  props: ["name", "dir", "num", "dep", "localidad", "provincia", "tel", "esp", "plan"],
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}
ul li {
  text-align: left;
}
.detail{
  padding-bottom: 10px;
}
.detail .name {
  color: #00758f;
  font-size: 18px;
}
.detail .skill {
  color: #424242;
  font-size: 14px;
  font-style: italic;
}
.detail .info {
  display: block;
  color: #424242;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
