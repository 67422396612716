<template>
  <section>
    <div id="instafeed" class="columns is-mobile is-multiline"></div>
  </section>
</template>

<script>
export default {
  name: 'InstagramCarousel',
}
</script>

<style scoped>
img {
  display: block;
  margin: 0 auto;
}
</style>
