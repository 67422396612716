<template>
  <section class="container-fluid" id="contentFix">
    <div class="columns is-align-items-left is-multiline is-mobile">
      <div class="column is-6-desktop">
        <section
          class="columns is-align-items-center is-multiline is-mobile mb-3"
        >
          <div class="column is-12-mobile is-6-desktop text">
            Buscar por nombre
          </div>
          <div class="column is-12-mobile is-6-desktop">
            <input
              @input="searchData"
              type="text"
              placeholder="Buscar por nombre"
              class="input"
            />
          </div>
        </section>
      </div>
      <div class="column is-6-desktop">
        <section
          class="columns is-align-items-center is-multiline is-mobile mb-3"
        >
          <div class="column is-12-mobile is-auto-desktop text">
            <label class="typo__label">Ciudad</label>
          </div>
          <div class="column is-12-mobile is-auto-desktop">
            <Multiselect
              v-model="citySelected"
              placeholder="Seleccionar"
              label="name"
              :limit="1"
              track-by="name"
              :options="cities"
              :multiple="true"
              :taggable="true"
              :openDirection="'bottom'"
              :showLabels="false"
              :limitText="(count) => `y ${count} más`"
              @tag="addTag"
            >
              <template slot="option" slot-scope="props">{{
                props.option.name
              }}</template>
            </Multiselect>
            <!-- <multiselect
              v-model="citySelected"
              @input="cityChange"
              track-by="name"
              label="name"
              placeholder="Seleccionar"
              :options="cities"
              :searchable="false"
              :showLabels="false"
              :openDirection="'bottom'"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect> -->
          </div>
        </section>
      </div>

      <div class="column is-12">
        <ul class="result" :key="this.key_result">
          <li v-for="(i, k) in this.result" :key="k">
            <Professional
              :email="i.Email"
              :homepage="i.Homepage"
              :alias="i.Alias"
              :skill="i.Especialidad"
              :address="i.Direccion"
              :tipology="i.Tipologia"
              :city="i.Ciudad"
              :province="i.Provincia"
              :telephone="i.Telefono"
            />
          </li>
        </ul>
      </div>
      <div class="column is-6-mobile is-6-desktop mb-5">
        <nav
          class="pagination is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <ul class="pagination-list">
            <li
              v-for="n in Math.ceil(
                this.resultFilter.length / this.itemPerPage
              )"
              :key="n"
            >
              <a
                @click="setPage(n)"
                v-bind:class="{
                  'pagination-link': true,
                  'is-current': n === $store.getters.getTotalList.page,
                }"
                aria-label="Goto page 2"
                >{{ n }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-6-mobile is-6-desktop mb-5">
        <ButtonDownloader :filename="this.fileNameText">
          <PLPrestadores :list="this.resultFilter" :key="key_pdf" />
        </ButtonDownloader>
      </div>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import PLPrestadores from "./../PdfDownloader/PLPrestadores";
import ButtonDownloader from "./../PdfDownloader/ButtonDownloader";
// import Select from "./../form/Select";
import Professional from "./Professional";
export default {
  name: "DataTable",
  props: ["data"],
  data() {
    return {
      key_result: 0,
      key_pdf: 0,
      result: this.data,
      resultFilter: this.data,
      currentPage: 1,
      itemPerPage: 9,
      search: "",
      proTipology: [],
      proTipologySelected: "",
      cities: [],
      citySelected: null,

      fileNameText: `OSFATUN - Cartilla de Prestadores - ${this.data[0].Provincia}`,
      initialinfo: null,
    };
  },
  components: {
    Professional,
    // Select,
    ButtonDownloader,
    PLPrestadores,
    Multiselect,
  },
  watch: {
    result: function () {
      this.key_result++;
    },
    citySelected: function () {
      this.applyFilters();
    },
    resultFilter: function (newData) {
      this.resultFilter = newData;
      // Actualizamos los datos del pdf
      this.initialinfo.cities = this.citySelected;
      this.$store.commit("setInfoPdf", this.initialinfo);

      this.key_pdf++;
    },
  },
  created() {
    this.initialinfo = this.$store.getters.getInfoPdf;
    this.setDataForFilters();
    this.applyFilters();
  },
  methods: {
    searchData(e) {
      this.search = e.target.value;
      this.applyFilters();
    },
    cityChange() {
      this.applyFilters();
    },
    applyFilters() {
      this.resultFilter = this.groupEspecialidad(this.data);
      //Alias
      if (this.search !== "") {
        this.resultFilter = this.resultFilter.filter(({ Alias }) => {
          return new String(Alias)
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      // Filtrar por ciudad.
      if (this.citySelected !== null && this.citySelected.length !== 0) {
        this.resultFilter = this.resultFilter.filter(({ Ciudad }) => {
          let result = false;
          this.citySelected.map((e) => {
            if (Ciudad === e.name) {
              result = true;
            }
          });
          return result;
        });
      }

      const totalList = this.$store.getters.getTotalList;
      totalList.total = this.resultFilter.length;
      totalList.page = 1;

      this.$store.commit("setTotalList", totalList);
      this.result = this.resultFilter.slice(0, this.itemPerPage);
    },
    setPage(p) {
      window.scroll(0, 0);
      const totalList = this.$store.getters.getTotalList;
      totalList.page = p;
      // this.result = this.resultFilter.slice(p * this.itemPerPage, p * this.itemPerPage);
      // console.log((p - 1) * this.itemPerPage, p * this.itemPerPage);

      this.result = this.resultFilter.slice(
        (p - 1) * this.itemPerPage,
        p * this.itemPerPage
      );

      this.$store.commit("setTotalList", totalList);
    },
    setDataForFilters() {
      let auxCities = [];
      this.data.map((item) => {
        if (item.Ciudad) {
          auxCities.push(item.Ciudad);
        }
      });
      this.cities = [];
      auxCities = this.eliminarDuplicados(auxCities).sort();
      auxCities.forEach((e) => this.cities.push({ name: e }));
    },
    eliminarDuplicados(data) {
      const dataArr = new Set(data);
      return [...dataArr];
    },
    equals(str1, str2) {
      return str1.toUpperCase() === str2.toUpperCase();
    },
    groupEspecialidad(arr) {
      const objectReduce = arr.reduce((prev, current) => {
        const key = current.Nombre + current.Alias;
        prev[key] = prev[key]
          ? prev[key]
          : {
              ...current,
              Especialidad: {},
            };
        prev[key].Especialidad[current.Especialidad] = current.Especialidad;
        return prev;
      }, {});

      const res = Object.keys(objectReduce).map((item) => ({
        ...objectReduce[item],
        Especialidad: Object.keys(objectReduce[item].Especialidad).map(
          (p) => p
        ),
      }));

      return res;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>

<style scoped>
ul.result {
  margin: 0;
  padding: 0;
}
ul.result li {
  list-style: none;
}
.text {
  font-size: 20px !important;
  line-height: 34px;
  color: #00758f;
}

.button {
  background-color: #00758f !important;
  color: #fff;
  font-weight: bold;
  font-size: 21px !important;
  border-radius: 0;
  line-height: 30.23px;
  font-family: "Gotham Rounded" !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.button:hover {
  background-color: #00758f !important;
  transition: 0.4s all ease;
}
</style>

<style>
.pagination-link {
  border-radius: 100% !important;
}
.pagination-link.is-current {
  background-color: #00758f !important;
  border-color: #00758f !important;
  color: #fff;
}
</style>