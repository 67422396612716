<template>
  <header class="is-shadow">
    <nav class="container is-fluid">
      <div class="columns is-align-items-center is-multiline is-mobile pt-2">
        <div class="column has-text-left is-3-mobile is-4-desktop">
          <img
            class="btnMenu"
            src="./../../assets/menu/menu.svg"
            alt="menu"
            @click="openNav"
          />
        </div>
        <div
          class="column has-text-centered is-6-mobile is-4-desktop mt-1 pb-1"
        >
          <a href="https://osfatun.com.ar/">
            <img
              class="osfatun-1"
              src="./../../assets/logo/osfatun-1.svg"
              alt="Osfatun"
            />
          </a>
        </div>
        <div class="column has-text-right is-3-mobile is-4-desktop">
          <a href="http://mi.osfatun.com.ar" target="_blank">
            <img
              v-if="responsive"
              src="./../../assets/logo/miOsfatun-responsive.svg"
              class="miOsfatun"
              alt="mi osfatun"
            />
            <img
              v-else
              src="./../../assets/logo/miOsfatun.svg"
              class="miOsfatun"
              alt="mi osfatun"
            />
          </a>
        </div>
      </div>
    </nav>
    <section id="side-nav" class="sidenav">
      <template v-if="responsive">
        <section class="container is-fluid">
          <div class="columns is-multiline is-mobile">
            <div class="column is-6-mobilehas-text-left">
              <router-link to="https://osfatun.com.ar/">
                <img
                  src="./../../assets/logo/osfatun-1-blanco.svg"
                  alt="Osfatun"
                />
              </router-link>
            </div>
            <div class="column is-6-mobile has-text-right">
              <button class="closebtn" @click="closeNav">
                <img src="./../../assets/header/close.svg" alt="" />
              </button>
            </div>
            <div class="column is-12">
              <ul class="sidenav-menu">
                <li v-for="(i, k) in menuList" :key="k" class="pl-5 pb-1">
                  <a :href="i.link" :target="i.target">{{ i.title }}</a>
                </li>
              </ul>
            </div>
            <div class="column is-12">
              <RedSocials :type="'header'" />
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <div class="columns">
          <div class="column is-narrow">
            <button class="closebtn mb-3" @click="closeNav">
              <img src="./../../assets/header/close.svg" alt="" />
              Cerrar Menú
            </button>
            <ul class="sidenav-menu">
              <li v-for="(i, k) in menuList" :key="k" class="pl-5 pb-1">
                <a :href="i.link" :target="i.target">{{ i.title }}</a>
              </li>
            </ul>
          </div>
          <div class="column">
            <RedSocials :type="'header'" />
          </div>
        </div>
      </template>
    </section>
  </header>
</template>
<script>
import { isMobile } from 'mobile-device-detect'
import RedSocials from './../footer/RedSocials'
export default {
  name: 'Header',
  components: {
    RedSocials,
  },
  data() {
    return {
      responsive: false,
      menuList: [
        { link: 'https://osfatun.com.ar/', title: 'Inicio' },
        {
          link: 'http://mi.osfatun.com.ar',
          title: 'Mi OSFATUN',
          target: '_blank',
        },
        {
          link: 'https://cotizador.osfatun.com.ar/',
          title: 'Cotizador online',
          target: '_self',
        },
        { link: 'https://osfatun.com.ar/planes', title: 'Planes' },
        {
          link: 'https://prestadores.osfatun.com.ar/',
          title: 'Cartilla de prestadores',
          target: '_self',
        },
        { link: 'https://osfatun.com.ar/delegaciones', title: 'Delegaciones' },
        { link: 'https://osfatun.com.ar/medios-de-pago', title: 'Medios de pago' },
        { link: 'https://osfatun.com.ar/formularios', title: 'Formularios' },
        // { link: 'https://osfatun.com.ar/vademecum', title: 'Vademécum' },
        { link: 'https://osfatun.com.ar/beneficiarios', title: 'Beneficiarios' },
        // { link: '/blog', title: 'Blog y novedades' },
        { link: 'https://osfatun.com.ar/servicios-de-emergencia', title: 'Servicios de emergencia' },
        {
          link: 'http://www.fiscalizacion.com.ar/index.htm',
          title: 'Fiscalización',
        },
        { link: 'https://osfatun.com.ar/contacto', title: 'Contacto' },
        { link: 'https://osfatun.com.ar/recursos-humanos', title: 'Trabajá con nosotros - RRHH' },
        { link: 'https://osfatun.com.ar/solicitud-de-baja', title: 'Form. baja del servicio' },
        { link: 'https://osfatun.com.ar/arrepentimiento', title: 'Form. Arrepentimiento' },
        { link: 'https://osfatun.com.ar/nosotros', title: 'Sobre OSFATUN' },
      ],
    }
  },
  mounted() {
    this.responsive = isMobile
  },
  methods: {
    openNav: () => document.getElementById('side-nav').classList.add('activo'),
    closeNav: () =>
      document.getElementById('side-nav').classList.remove('activo'),
  },
}
</script>
<style scoped>
.b-tooltip.is-primary .tooltip-content {
  background: #00758f !important;
}
.sidenav {
  height: 100%;
  width: 0;
  padding-left: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgb(0, 147, 191);
  background: linear-gradient(
    45deg,
    rgba(0, 147, 191, 1) 0%,
    rgba(0, 117, 143, 1) 100%
  );
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
}
.sidenav.activo {
  width: 100%;
  padding-left: 30px;
}
.sidenav-menu {
  margin: 0;
  margin-left: 32px;
  list-style: none;
  height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  direction: rtl;
}
.sidenav-menu li {
  text-align: left;
}
.closebtn {
  padding: 8px 8px 8px 32px;
  font-size: 24px;
  background: transparent;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
.sidenav .closebtn img {
  height: 27px;
  width: 27px;
  vertical-align: bottom;
}
.sidenav a {
  text-decoration: none;
  font-size: 24px;
  color: #fff;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
/*  */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #02839f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #015c70;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #014a5a;
}
/*  */
header {
  box-shadow: 1px 4px 27px -15px rgba(0, 0, 0, 0.75);
  margin-bottom: 10px;
}
img.osfatun-1 {
  height: 75px;
}
img.miOsfatun {
  height: 25px;
}
.btnMenu {
  cursor: pointer;
  height: 21px;
}
.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-list li {
  margin-right: 10px;
  display: inline-block;
}
.menu-list li img {
  height: 24px;
}
.menu-list li:last-child {
  margin-right: 0px;
}
@media screen and (max-width: 42em) {
  nav.container.is-fluid {
    padding: 0 20px;
  }
  img.btnMenu {
    height: 25px;
  }
  img.osfatun-1 {
    height: 45px;
  }
  .sidenav.activo {
    width: 100%;
    padding-left: 0px;
    padding-top: 30px;
  }
  .sidenav-menu {
    margin-left: 0px;
  }
}
</style>
