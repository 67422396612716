<template>
  <section class="has-text-left" id="contentFix">
    <h3 class="title mb-5"></h3>
    <form @submit="sendOdontologo">
      <section class="container-fluid">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12-mobile is-5-desktop">
            <Select
              :data="provincias"
              :title="'Provincia'"
              :type="'name'"
              :anytext="'Seleccionar'"
              @change="setProvinciaSelected"
            />
          </div>
          <div class="column is-12-mobile is-5-desktop">
            <Select :title="'Localidad'" :type="'slot'">
              <Multiselect
                v-model="localidadSelected"
                placeholder="Seleccionar"
                label="name"
                :limit="1"
                track-by="name"
                :options="locForMultiselect"
                :multiple="true"
                :taggable="true"
                :openDirection="'bottom'"
                :showLabels="false"
                :limitText="(count) => `y ${count} más`"
                @tag="addTag"
              >
                <template slot="option" slot-scope="props">{{
                  props.option.name
                }}</template>
              </Multiselect>
            </Select>
          </div>
          <div class="column is-12-mobile is-2-desktop">
            <button
              :disabled="btnIsDisable"
              type="submit"
              class="button is-info is-fullwidth"
            >
            Buscar
          </button>
          </div>
        </div>
      </section>
    </form>
    <template v-if="this.csvDataTable.length > 0">
      <section class="container-fluid">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12-mobile is-12-desktop">
            <ul class="farmacia-list">
              <li class="farmacia-item" v-for="(i,k) in this.csvDataTable" :key="k">
                <Odontologo
                  :name="i.ApellidoNombre"
                  :dir="i.Direccion"
                  :localidad="i.Localidad"
                  :provincia="i.Provincia"
                  :num="i.Numero"
                  :dep="i.Depto"
                  :tel="i.Telefono"
                  :esp="i.Especialidad"
                  :plan="i.PlanesHabilitados"
                />
              </li>
            </ul>
          </div>
          <div class="column is-12-mobile is-12-desktop mb-5 has-text-right">
            <ButtonDownloader :filename="'OSFATUN - Odontologos'" :key="this.versionDownloader">
              <PLOdontologos :list="this.csvDataTable" />
            </ButtonDownloader>
          </div>
        </div>
      </section>
    </template>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import Select from "./../form/Select";
import Odontologos from "./../../assets/data/odontologos.json";
import Odontologo from './Odontologo'
import ButtonDownloader from './../PdfDownloader/ButtonDownloader'
import PLOdontologos from './../PdfDownloader/PLOdontologos'

export default {
  name: "TabOdontologos",
  components: {
    Select,
    Odontologo,
    ButtonDownloader,
    PLOdontologos,
    Multiselect,
  },
  data() {
    return {
      //provincia es region
      //localidades es zona
      btnIsDisable: true,
      csvDataTable: [],

      provincias: [],
      localidades: [],
      provinciaSelected: '',
      localidadSelected: '',
      isLoading: false,
      versionDownloader: 1,

      locForMultiselect: [],
    };
  },
  watch: {
    locForMultiselect: function(){
      this.versionDownloader++;
    },
    localidadSelected: function () {
      this.btnIsDisable = this.localidadSelected.length > 0 ? false : true;
    },
  },
  created() {
    this.provincias = this.getAllProvincias(Odontologos);
  },
  methods: {
    setProvinciaSelected(value){
      this.localidadSelected = this.locForMultiselect = [],
      this.provinciaSelected = value;
      if (this.provinciaSelected !== '') {
        this.localidadSelected = '';
        this.locForMultiselect = this.getAllLocalidades(Odontologos, this.provinciaSelected)
        this.btnIsDisable = false;
      }
    },
    setLocalidadSelected(value){
      this.localidadSelected = value;
      if(this.localidadSelected !== ''){
        this.btnIsDisable = false;
      }
    },
    getAllProvincias(arr){
      let aux = [];
      arr.forEach((e) => {
        if (e.Provincia !== '') {
          aux.push(e.Provincia);
        }
      });
      //Eliminar duplicados
      const auxAntiDup = new Set(aux);
      aux = [...auxAntiDup];
      //Retorno ordenado menor a mayor

      return Object.values(aux);
    },
    getAllLocalidades(arr, provincia){
      let values = [];
      arr.forEach((e) => {
        if (e.Localidad !== '' && e.Provincia === provincia) {
          values.push(e.Localidad);
        }
      });
      //Eliminar duplicados
      const dataAux = new Set(values);
      values = [...dataAux];
      //Retorno ordenado menor a mayor
      values = Object.values(values);

      const result = [];
      values.map(e => result.push({name: e}))
      return result;
    },
    sendOdontologo(e){
      e.preventDefault();
      this.isLoading = true;
      e.target[2].classList.add('is-loading') // boton loading
      this.csvDataTable = [];
      Odontologos.forEach(el => {
        if (this.localidadSelected.length > 0) {
          this.localidadSelected.map(({name})=>{
            if(el.Provincia.toLowerCase() === this.provinciaSelected.toLowerCase() &&
               el.Localidad.toLowerCase() === name.toLowerCase()){
              //Encontramos las farmacias disponibles
              this.csvDataTable.push(el)
            }

          })
        }else{
          if(e.Provincia.toLowerCase() === this.provinciaSelected.toLowerCase()){
            //Encontramos las farmacias disponibles
            this.csvDataTable.push(e)
          }
        }
      })
      e.target[2].classList.remove('is-loading') // boton loading
      this.isLoading = false;
      this.$store.commit("setInfoPdf", {
        province: this.provinciaSelected,
        town: this.localidadSelected
      });
      this.versionDownloader++;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>

<style scoped>
.farmacia-list{
  margin: 0; padding: 0;
  text-align: left;
  padding-left: 20px;
}
.farmacia-item{
  list-style: none;
 
}
.button {
  background-color: #00758f !important;
  color: #fff;
  font-weight: bold;
  font-size: 21px !important;
  border-radius: 0;
  line-height: 30.23px;
  font-family: "Gotham Rounded" !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.button:hover {
  background-color: #00758f !important;
  transition: 0.4s all ease;
}

@media screen and (max-width: 42em) {
  .button {
    font-size: 20px !important;
  }
}
</style>
