<template>
  <section class="columns mb-2 is-multiline is-mobile has-text-left">
    <div :class="'column is-12-mobile text-' + this.type">{{ this.title }}</div>
    <div class="column is-12-mobile">
      <template v-if="this.type == 'id-name'">
        <div class="select">
          <select v-model="selected" @change="onChange()">
            <option disabled value="">{{ anytext }}</option>
            <template v-if="data">
              <option
                v-for="option in data"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </template>
          </select>
        </div>
      </template>

      <template v-if="this.type == 'slot'">
        <slot></slot>
      </template>

      <template v-else>
        <div class="select">
          <select v-model="selected" @change="onChange()">
            <template v-if="data && type == 'name'">
              <option disabled value="">{{ anytext }}</option>
              <option v-for="(option, k) in data" :value="option" :key="k">
                {{ option }}
              </option>
            </template>
          </select>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "Select",
  props: ["data", "title", "type", "anytext"],
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    onChange() {
      this.$emit("change", this.selected);
    },
  },
};
</script>

<style scoped>
.text-id-name,
.text-slot,
.text-name {
  font-size: 19px;
  color: #00758f;
}
select {
  border-radius: 0;
  border: 0;
  border-bottom: 3px solid #fbaf33;
  color: #00758f;
}
@media screen and (max-width: 42em) {
  select {
    width: 190px !important;
  }
  .text-id-name,
  .text-slot,
  .text-name {
    font-size: 20px;
    color: #00758f;
  }
}
</style>
