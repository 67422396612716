<template>
  <main>
    <Header />
    <div class="sentence">
      <img
        src="./../assets/title-prestadores.svg"
        class="titleImg"
        alt="osfatun"
      />
    </div>
    <section class="content">
      <b-tabs
        position="is-centered"
        v-model="activeTab"
        :multiline="true"
        expanded
      >
        <!-- Cartilla de prestadores -->
        <b-tab-item
          v-if="dataTabs[0].displayed"
          :key="dataTabs[0].id"
          :value="dataTabs[0].id"
          :label="dataTabs[0].label"
        >
          <TabPrestadores />
        </b-tab-item>

        <!-- Farmacias -->
        <b-tab-item
          v-if="dataTabs[1].displayed"
          :key="dataTabs[1].id"
          :value="dataTabs[1].id"
          :label="dataTabs[1].label"
        >
          <TabFarmacias />
        </b-tab-item>

        <!-- Odontologos -->
        <b-tab-item
          v-if="dataTabs[2].displayed"
          :key="dataTabs[2].id"
          :value="dataTabs[2].id"
          :label="dataTabs[2].label"
        >
          <TabOdontologos />
        </b-tab-item>

        <!-- Emergencias 
        <b-tab-item
          v-if="dataTabs[3].displayed"
          :key="dataTabs[3].id"
          :value="dataTabs[3].id"
          :label="dataTabs[3].label"
        >
          <TabEmergencias />
        </b-tab-item>-->
      </b-tabs>
    </section>
    <Footer />
  </main>
</template>

<script>
import Header from "./../components/header/Header";
import Footer from "./../components/footer/Footer";
import TabPrestadores from "./../components/tabs/TabPrestadores";
import TabFarmacias from "./../components/tabs/TabFarmacias";
import TabOdontologos from "./../components/tabs/TabOdontologos";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    TabPrestadores,
    TabFarmacias,
    TabOdontologos,
  },
  metaInfo: {
    title: "OSFATUN",
  },
  created(){
    let hash = this.$route.hash.replace('#', '');
    console.log(hash)
    this.dataTabs.map( (e,k) => {
      if(e.id === hash){
        this.activeTab = k
        return;
      }
    })
  },
  data() {
    return {
      activeTab: 0,
      dataTabs: [
        {
          id: "cartilla-prestadores",
          label: "PRESTADORES",
          displayed: true,
        },
        {
          id: "farmacias",
          label: "FARMACIAS",
          displayed: true,
        },
        {
          id: "odontologos",
          label: "ODONTÓLOGOS",
          displayed: true,
        },
        
      ],
    };
  },
};
</script>

<style scoped>
.titleImg {
  width: 476px;
}
.sentence {
  background-image: url("./../assets/background.png");
  background-size: 100%;
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
}
.sentence img {
  margin: 0 auto;
  padding-top: 10%;
  text-align: center;
  display: block;
}
.content {
  position: relative;
  top: -15vh;
  background: white;
  max-width: 83%;
  padding: 40px 30px;
  margin: 0 auto;
}
h1.title {
  font-size: 49px;
  color: #00758f;
  margin-bottom: 6%;
}

@media screen and (max-width: 42em) {
  h1.title {
    font-size: 28px;
    margin-bottom: 10%;
  }
  .sentence {
    height: 20vh;
  }
  .content {
    top: -4vh;
    padding: 20px 5px;
  }
  .titleImg {
    width: 320px;
  }
  .b-tabs .tab-content {
    padding: 0 !important;
  }
}
</style>
<style>
.tabs li.is-active a {
  border-bottom-color: #e0a034 !important;
  color: #e0a034 !important;
}
</style>