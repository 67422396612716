<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    title: "Cartilla de prestadores - OSFATUN",
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "author", content: "OSFATUN - Cartilla prestadores" },
      {
        name: "description",
        content:
          "Conocé nuestra Cartilla Médica - OSFATUN es la Obra Social de los Trabajadores NODOCENTES de las Universidades Nacionales.",
      },
      {
        name: "keywords",
        content:
          "OSFATUN,Obra Social,NODOCENTES,Universidades Nacionales. Argentina, cartilla, prestadores",
      },
    ],
  },
};
</script>
<style>
@import "~bulma/css/bulma.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "assets/GothamRounded/main-loader.css";
html {
  scroll-behavior: smooth;
  background-color: #f4f4f4;
}
#app {
  font-family: "Gotham Rounded", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* global multiselects */
#contentFix .multiselect__content {
  list-style: none !important;
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 100% !important;
  vertical-align: top !important;
}
#contentFix .multiselect__content-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
}
#contentFix .typo__label,
#contentFix .label {
  font-size: 19px;
  color: #00758f;
}
#contentFix .typo__label {
  display: block;
  margin-bottom: 9px;
}

#contentFix .multiselect__option {
  display: block;
  padding: 7px 5px;
  min-height: auto;
  line-height: 15px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
#contentFix .multiselect__select:after,
#contentFix .multiselect__select:before {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
  margin-left: 10px;
  border-radius: 7px;
}
#contentFix .multiselect__select:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
}
#contentFix .multiselect__select:before {
  border-color: rgba(0, 16, 245, 0);
  border-top-color: #00758f;
}
#contentFix .select:not(.is-multiple):not(.is-loading)::after {
  border-color: #00758f;
}
#contentFix .multiselect__option--highlight {
  background-color: #00758f;
}
#contentFix .multiselect__content-wrapper {
  width: 350px;
}
</style>
