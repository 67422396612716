<template>
  <section class="has-text-left">
    <h3 class="title mb-5"></h3>
    <form @submit="sendFarmacia" id="contentFix">
      <section class="container-fluid">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12-mobile is-5-desktop">
            <Select
              :data="provincias"
              :title="'Provincia'"
              :type="'name'"
              :anytext="'Seleccionar'"
              @change="setProvinciaSelected"
            />
          </div>
          <div class="column is-12-mobile is-5-desktop" :key="pronvinciaUpdate">
            <Select :title="'Localidad / Zona'" :type="'slot'">
              <Multiselect
                v-model="localidadSelected"
                placeholder="Seleccionar"
                label="name"
                :limit="1"
                track-by="name"
                :options="locForMultiselect"
                :multiple="true"
                :taggable="true"
                :openDirection="'bottom'"
                :showLabels="false"
                :limitText="(count) => `y ${count} más`"
                @tag="addTag"
              >
                <template slot="option" slot-scope="props">{{
                  props.option.name
                }}</template>
              </Multiselect>
            </Select>
          </div>
          <div class="column is-12-mobile is-2-desktop">
            <button
              :disabled="btnIsDisable"
              type="submit"
              class="button is-info is-fullwidth"
            >
              Buscar
            </button>
          </div>
        </div>
      </section>
    </form>
    <template v-if="this.csvDataTable.length > 0">
      <section class="container-fluid">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12-mobile is-12-desktop">
            <ul class="farmacia-list">
              <li
                class="farmacia-item"
                v-for="(i, k) in this.csvDataTable"
                :key="k"
              >
                <Farmacia
                  :name="i.Nombre"
                  :dir="i.Domicilio"
                  :region="i.Region"
                  :zona="i.Zona"
                  :tel="i.Telefono"
                  :code="i.Codigo"
                />
              </li>
            </ul>
          </div>
          <div class="column is-12-mobile is-12-desktop mb-5 has-text-right">
            <ButtonDownloader
              :filename="'OSFATUN - Farmacias'"
              :key="this.versionDownloader"
            >
              <PLFarmacias :list="this.csvDataTable" />
            </ButtonDownloader>
          </div>
        </div>
      </section>
    </template>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import ButtonDownloader from "./../PdfDownloader/ButtonDownloader";
import PLFarmacias from "./../PdfDownloader/PLFarmacias";
import Farmacias from "./../../assets/data/farmacias.json";
import Select from "./../form/Select";
import Farmacia from "./Farmacia";
export default {
  name: "TabFarmacias",
  components: {
    Farmacia,
    ButtonDownloader,
    PLFarmacias,
    Multiselect,
    Select,
  },
  data() {
    return {
      //provincia es region
      //localidades es zona
      btnIsDisable: true,
      csvDataTable: [],

      provincias: [],
      localidades: [],
      provinciaSelected: "",
      localidadSelected: [],

      locForMultiselect: [],

      isLoading: false,

      pronvinciaUpdate: 1,
      versionDownloader: 1,
    };
  },
  watch: {
    csvDataTable: function(){
      this.versionDownloader++;
      // this.csvDataTable= newData
    },
    localidadSelected: function () {
      this.btnIsDisable = this.localidadSelected.length > 0 ? false : true;
    },
  },
  created() {
    this.provincias = this.getAllProvincias(Farmacias);
  },
  methods: {
    setProvinciaSelected(value) {
      this.localidades = [];
      this.localidadSelected = "";
      this.provinciaSelected = value;
      if (this.provinciaSelected !== "") {
        this.btnIsDisable = false;
        this.localidades = this.getAllLocalidades(
          Farmacias,
          this.provinciaSelected
        );

        if (this.localidades.length > 0) {
          this.locForMultiselect = [];

          // this.locForMultiselect.push({ name: "Todos" });
          this.localidades.map((e) => {
            this.locForMultiselect.push({ name: e });
          });
        }

        this.pronvinciaUpdate++;
      }
    },
    getAllProvincias(arr) {
      let aux = [];
      arr.forEach((e) => {
        if (e.Region !== "") {
          aux.push(e.Region);
        }
      });
      //Eliminar duplicados
      const dataAux = new Set(aux);
      aux = [...dataAux];
      //Retorno ordenado menor a mayor
      return Object.values(aux);
    },
    getAllLocalidades(arr, region) {
      let aux = [];
      arr.forEach((e) => {
        if (e.Region !== "" && e.Zona !== "" && e.Region === region) {
          aux.push(e.Zona);
        }
      });
      //Eliminar duplicados
      const dataAux = new Set(aux);
      aux = [...dataAux];
      //Retorno ordenado menor a mayor
      return aux.sort();
    },
    sendFarmacia(e) {
      e.preventDefault();
      this.isLoading = true;
      e.target[2].classList.add("is-loading"); // boton loading
      this.csvDataTable = [];
      Farmacias.forEach((el) => {
        if (this.localidadSelected.length > 0) {
          this.localidadSelected.map( ({name}) =>{
            if (
              el.Region.toLowerCase() === this.provinciaSelected.toLowerCase() &&
              el.Zona.toLowerCase() === name.toLowerCase()) {
              //Encontramos las farmacias disponibles
              this.csvDataTable.push(el);
              return;
            }
          })
        } else {
          if (el.Region.toLowerCase() === this.provinciaSelected.toLowerCase()) {
            //Encontramos las farmacias disponibles
            this.csvDataTable.push(el);
          }
        }
      });
      e.target[2].classList.remove("is-loading"); // boton loading
      this.isLoading = false;
      this.$store.commit("setInfoPdf", {
        region: this.provinciaSelected,
        zona: this.localidadSelected,
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>

<style scoped>
.farmacia-list {
  margin: 0;
  padding: 0;
  text-align: left;
  padding-left: 20px;
}
.farmacia-item {
  list-style: none;
}
.button {
  background-color: #00758f !important;
  color: #fff;
  font-weight: bold;
  font-size: 21px !important;
  border-radius: 0;
  line-height: 30.23px;
  font-family: "Gotham Rounded" !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.button:hover {
  background-color: #00758f !important;
  transition: 0.4s all ease;
}

@media screen and (max-width: 42em) {
  .button {
    font-size: 20px !important;
  }
}
</style>
