<template>
  <section>
    <h3 class="title mb-5">
      Mediante nuestro buscador podrás encontrar los prestadores de nuestra
      cartilla, según los filtros seleccionados.
    </h3>
    <FormPrestadores @change="updateDataTable" />
    <hr />
    <template v-if="Object.keys(this.csvDataTable).length">
      <section class="second-container">
        <DataTable :data="this.csvDataTable" />
      </section>
    </template>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </section>
</template>

<script>
import {getPlanName,getProvinciaName} from './../../helpers/utils'
import FormPrestadores from './../prestadores/FormPrestadores'
import DataTable from "./../table/DataTable";
export default {
  name: "TabPrestadores",
  components: {
    DataTable,
    FormPrestadores
  },
  data() {
    return {
      csvDataTable: [],
      paramsTable: {},
      isLoading: false,
    };
  },
  created() {
    // this.paramsTable = this.$store.getters.getParamsTable;
  },
  methods: {
    async firstFetch(csvFilePath, customURL){
      this.isLoading = true;
      await fetch(customURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: csvFilePath }),
      })
      .then((res) => {
        this.csvDataTable = [];
        return res.json();
      })
      .then((res) => {
        this.csvDataTable = res.data.filter((d) => d.Nombre);
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        throw err;
      });
    },
    async secondFetch(csvFilePath, customURL){
      await fetch(customURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: csvFilePath }),
      })
      .then( res  => res.json() )
      .then((res) => {
        this.isLoading = false;
        return res.data.filter((d) => d.Nombre);
      })
      .catch((err) => {
        this.isLoading = false;
        throw err;
      });
    },
    async updateDataTable(obj) {
      let infoPdf = this.$store.getters.getInfoPdf;
          infoPdf.plan = getPlanName(obj.plan);
          infoPdf.province = getProvinciaName(obj.province);
          infoPdf.cities = null
      this.$store.commit('setInfoPdf', infoPdf)

      const gcsvfp = this.generateCsvFilePath(
          obj.province,
          obj.plan,
          obj.typologi.value,
          obj.skills
        )

      await this.firstFetch(
       gcsvfp,
        "https://functions.osfatun.com.ar/csvparse"
      );
      this.$store.commit("setTotalList", {
        total: this.csvDataTable.filter((d) => d.Nombre).length,
        page: 1,
      });
    },
    encodeSpace(text){
      return text.replace(" ","%20")
    },
    generateCsvFilePath(province = "", plan, typologi, skills){
      let plan_mod = String(plan).replace("-","")
      let csvFilePath = `http://www.dipres.com.ar/providers/list_from_state.csv?management_id=1&state_id=${province}&plan_import_code=${plan_mod}`;
      
      if (typologi) {
        let format = this.encodeSpace(typologi)
        csvFilePath +=`&typology=${format}`;
      }
      

      if (skills.length > 0) {
        let sk = [];
        for (let i = 0; i < skills.length; i++) {
          sk.push(skills[i].Codigo)
        }
        csvFilePath+=`&specialties=[${sk}]`;
      }
      return csvFilePath
    },
  },
};
</script>
<style scoped>
h3.title {
  font-weight: bold;
  font-size: 22px;
  color: #00758f;
  line-height: 1.9rem;
  text-align: left;
}
.second-container {
  max-width: 90%;
  margin: 0 auto;
}
hr {
  background-color: #eeeeee;
}
h1.title {
  font-size: 49px;
  color: #00758f;
  margin-bottom: 6%;
}
@media screen and (max-width: 42em) {
  .prestadoresForm {
    max-width: 80%;
  }
  h1.title {
    font-size: 28px;
    margin-bottom: 10%;
  }
  h3.title {
    font-size: 19px;
  }
}
</style>
