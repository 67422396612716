<template>
  <section class="download-pdf">
    <button @click="generateReport" class="button is-info">
      Descargar PDF
    </button>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="this.filename"
      :pdf-quality="1.5"
      :paginate-elements-by-height="790"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    ><section slot="pdf-content"><slot></slot></section></vue-html2pdf>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "ButtonDownloader",
  props: ["filename"],
  components: {
    VueHtml2pdf,
  },
  data(){
    return{
      isLoading: false,
    }
  },
  methods: {
    // Pdf start
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    beforeDownload() {
      this.isLoading = true;
    },
    hasDownloaded() {
      this.isLoading = false;
    },
    // pdf end
  },
};
</script>
<style scoped>
.button {
  background-color: #00758f !important;
  color: #fff;
  font-weight: bold;
  font-size: 21px !important;
  border-radius: 0;
  line-height: 30.23px;
  font-family: "Gotham Rounded" !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.button:hover {
  background-color: #00758f !important;
  transition: 0.4s all ease;
}
</style>