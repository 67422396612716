<template>
  <footer v-if="!responsive" >
    <section class="svg-background"></section>
    <RedSocials :type="'footer'" />
    <InstagramCarousel />
    <section class="footer-container">
      <section class="container">
        <div class="columns is-align-items-center is-multiline is-mobile">
          <div class="column is-4-desktop has-text-left">
            <a href="https://www.osfatun.com.ar/">
              <img width="216px" src="./../../assets/logo/osfatun-2.svg" />
            </a>
            <!-- <FiscalCode /> -->
          </div>
          <div class="column is-2-desktop has-text-centered">
            <ul class="list-footer">
              <li v-for="(i, k) in this.menuListOne" :key="k">
                <a :href="i.link">{{ i.title }}</a>
              </li>
            </ul>
          </div>
          <div class="column is-3-desktop has-text-centered">
            <ul class="list-footer">
              <li v-for="(i, k) in this.menuListTwo" :key="k">
                <a :href="i.link">{{ i.title }}</a>
              </li>
            </ul>
          </div>
          <div class="column is-3-desktop has-text-centered">
            <a target="_blank" href="https://www.argentina.gob.ar/sssalud">
              <img
                src="./../../assets/logo/sssaludconinfo.svg"
                width="336px"
                alt="zaro"
              />
            </a>
          </div>
        </div>
        <div class="columns zaro is-multiline is-mobile">
          <div class="column is-12-mobile is-6-desktop has-text-left">
            <a target="_blank" href="https://www.fatun.org.ar/"
              ><img
                class="logo-federacion"
                src="./../../assets/logo/federacion.svg"
            /></a>
          </div>
          <div class="column is-12-mobile is-6-desktop has-text-right">
            <a target="_blank" href="https://zaro.com.ar"
              ><img
                class="zaro"
                src="./../../assets/logo/zaro.svg"
                width="85"
                alt="zaro"
            /></a>
          </div>
        </div>
      </section>
    </section>
  </footer>
  <footer v-else>
    <RedSocials :type="'footer'" />
    <InstagramCarousel />
    <div class="columns is-multiline is-mobile is-vcentered mt-1">
      <div class="column is-6 has-text-right">
        <a href="https://www.osfatun.com.ar/">
          <img
            style="max-width: 100%; height: 55px"
            src="./../../assets/logo/osfatun-2.svg"
          />
        </a>
      </div>
      <div class="column is-6 has-text-left">
        <a target="_blank" href="https://www.argentina.gob.ar/sssalud">
          <img
            v-if="responsive"
            style="max-width: 100%; height: 90px"
            src="./../../assets/logo/sssaludconinfo-responsive.svg"
            alt="sssalud"
          />
          <img
            v-else
            style="max-width: 100%; height: 70px"
            src="./../../assets/logo/sssaludconinfo.svg"
            alt="sssalud"
          />
        </a>
      </div>
      <div class="column is-12-mobile">
        <ul class="two-grid">
          <!-- <li><FiscalCode /></li> -->
          <li>
            <a target="_blank" href="https://zaro.com.ar">
              <img
                style="max-width: 100%; height: 25px"
                src="./../../assets/logo/zaro.svg"
                alt="zaro"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import RedSocials from './RedSocials'
import InstagramCarousel from './instagram/InstagramCarousel'
export default {
  name: 'Footer',
  components: {
    InstagramCarousel,
    RedSocials,
  },
  data() {
    return {
      responsive: false,
      menuListOne: [
        {
          link: 'https://cotizador.osfatun.com.ar/',
          title: 'Cotizador online',
          target: '_blank',
        },
        { link: 'https://osfatun.com.ar/nosotros', title: 'Nosotros' },
        { link: 'https://osfatun.com.ar/delegaciones', title: 'Delegaciones' },
        { link: 'https://osfatun.com.ar/planes', title: 'Planes y servicios' },
        { link: 'https://osfatun.com.ar/beneficiarios', title: 'Beneficiarios' },
        // { link: '/', title: 'Discapacidad' },
      ],
      menuListTwo: [
        {
          link: 'https://prestadores.osfatun.com.ar/',
          title: 'Cartilla de prestadores',
          target: '_blank',
        },
        // { link: 'https://osfatun.com.ar/blog', title: 'Blog y Novedades' },
        {
          link: 'http://www.fiscalizacion.com.ar/',
          title: 'Fiscalizacion',
          target: '_blank',
        },
        { link: 'https://osfatun.com.ar/contacto', title: 'Contacto' },
        { link: 'https://osfatun.com.ar/arrepentimiento', title: 'Form. Arrepentimiento' },
      ],
    }
  },
  created(){
    this.responsive = isMobile;
  }
}
</script>




<style scoped>
.footer-container {
  margin-top: 50px;
  padding: 20px 0;
}
.svg-background {
  width: 100%;
  background: url('./../../assets/forBackground/nuestro-plan-es-cuidarte.svg')
    center center no-repeat;
  background-size: 100%;
  height: 253px;
  opacity: 0.6;
}
.zaro {
  margin-top: 10px;
}
.list-footer li {
  text-align: left;
}
.list-footer li a {
  color: #00758f;
  font-size: 18px;
  font-weight: bold;
}
.two-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 42em) {
  footer {
    padding-bottom: 0px;
  }
  .footer-container {
    margin-top: 0;
    overflow: hidden;
  }
  .zaro {
    margin-top: 10px;
  }
  .logo-federacion {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .svg-background {
    display: none;
  }
  .has-text-left,
  .has-text-right {
    text-align: center !important;
  }
}
</style>
